import * as React from "react";
import SimplePlaceholder from "../components/simple-placeholder";

// markup
const IndexPage = () => {
  return (
    <SimplePlaceholder />
  )
}

export default IndexPage
