import React from "react";
import Logo from "../images/22365391.png";
import { FaGithub } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

const codeSummary = `pub trait Job{}
struct MaximilianWolf {
    role: Vec<String>,
    coding_skills: Vec<String>,
    locale: Vec<String>,
}

impl Job for MaximilianWolf {
    fn get() -> Self {
        MaximilianWolf {
            role: vec!["Software Engineer", "IT Consultant", "Full Stack Dev"],
            coding_skills: vec!["Java", "Rust", "TypeScript", "Python"],
            locale: vec!["de_DE", "en_EN"],
        }
    }
}`

const mobileCode = `
fn get() -> Self {
    MaximilianWolf {
        role: vec![
            "Software Engineer",
            "IT Consultant",
            "Full Stack Dev"
        ],
        coding_skills: vec![
            "Java",
            "Rust",
            "TypeScript",
            "Python"
        ],
        locale: vec![
            "de_DE",
            "en_EN"
        ],
    }
}
`



const simplePlaceholder = () => {
  return (
    <div>
      <div className="hero min-h-screen bg-base-200">
        <div className="hero-content">
          <div className="mx-auto">
            <div className="flex flex-col justify-center text-center items-center space-y-5">
              <img src={Logo} className={"w-16 rounded object-center"} alt="Maximilian Wolf Logo" />
              <p className="mb-5">Hi! My name is Maximilian Wolf, <br />Software Engineer and IT Consultant from
                Germany.</p>
            </div>

            <div className={"container hidden xl:block"}>
              <div className="divider divider-accent">OR</div>
              <div className="mockup-code">
                {splitCode(codeSummary)}
              </div>
            </div>

            <div className={"container xl:hidden"}>
              <div className="divider divider-accent">OR</div>
              <div className="mockup-code">
                {splitCode(mobileCode)}
              </div>
            </div>

            <div className="divider divider-accent"></div>
            <nav className="grid-flow-col gap-4 md:place-self-center md:justify-self-end text-center">
              <div className="join join-vertical lg:join-horizontal">
                <a className="btn join-item" href={"https://github.com/wolfedme"} target={"_blank"}>{
                  <FaGithub />} Github</a>
                <a className="btn join-item" href={"https://linkedin.com/in/maximilian-wolf-909164228"}
                   target={"_blank"}>{<FaLinkedin />}LinkedIn</a>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <footer className="footer footer-center p-10 text-primary-content">
        <aside>
          <img src={Logo} className={"w-16 rounded"} alt="Maximilian Wolf Logo" />
          <p className="font-bold">
          Maximilian Wolf
          </p>
          <p>Copyright © 2024 - All right reserved</p>
        </aside>
      </footer>
    </div>
)
}

function splitCode(code: string) {
  return code.split(/\r?\n/).map((line, key) => {
    return <pre data-prefix={key + 1}>
      <code>{line}</code>
    </pre>;
  });
}

export default simplePlaceholder;